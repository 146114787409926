/*
** Titulos de la pagina uvm
 */

 .section-title-uvm {
    color: #d7282f;
    font-family: 'Rubik', sans-serif;
    font-size: 38px;
    font-weight: 700;
    letter-spacing: 0.3px;
    text-align: left;
    position: relative;
    margin-bottom: 45px;
}

.section-title-uvm:before {
    position: absolute;
    content: '';
    bottom: -7px;
    width: 40px;
    height: 2px;
    background-color: #767676;
}

.subtitle-uvm {
    font-size: 20px;
    letter-spacing: 0.3px;
    margin-top: 20px;
    line-height: 26px;
    font-weight: 400;
    margin-bottom: 10px;
}

.subtitle-uvm{
    color:#d7282f;
}


.section-title-historia-uvm
{
    position: absolute;
    content: '';
    bottom: -7px;
    width: 40px;
    height: 2px;
    background-color: #1B1C20;
}

#button-search-inicio button:hover
{
    background-color: #767676 !important;
    cursor: default;
}

/*
*** Numeros en el proceso de postulacion
 */




/*
** Estilos de navabar pagemenu
 */
#nav-uvm-menu li:hover {
    background-color: #767676;
    cursor: default;
}



/*
*** Seccion de requisiciones resumen
 */

.btn-search-resumen
{
    color: white;
    background-color: #d7282f;

}



/*
 Seccion Quienes somos
 */

.btn-ver-vacantes-campus
{
    color: white;
    background-color: #d7282f;
    padding: 10px 20px;
    letter-spacing: 1px;
    font-weight: 500;
    border-radius: 2px;
    box-shadow: 0 2px 2.7px 0.1px  #d7282f !important;
}
.campus-hover a:hover{
    color: #767676;
    cursor: default;
}

#icons-quienes
{
    width: 100px;
    height: 85px;
}

#section-ser-docente-2
{
    background-color: #d7282f;
}

#icons-stadistics
{
    background-color: white;
}

.subtitle-quienes{
    color: white;

}


/*
++Vista de resultados de busqueda
 */

.resultado-busqueda-materia{
    color: #d7282f !important;

}
.resultado-busqueda-subtitle
{
    color: #767676;
}

/*
   *** Vista preguntas- frecuentes
 */
#iconos-preguntas-frecuentes
{
    color: #d7282f;
}


/*
Vista Descripcion de VAcante. Materiallanding
 */
.btn-uvm, .input-group-btn .btn {
    border: none;
    border-radius: 2px;
    position: relative;
    padding: 8px 30px;
    margin: 10px 1px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0;
    will-change: box-shadow, transform;
    /* -webkit-transition: -webkit-box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1); */
    -webkit-transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    outline: 0;
    cursor: pointer;
    text-decoration: none;
}


.button-post-uvm {
    text-align: center;
    background-color: #d7282f;
    border-radius: 4px;
    padding: 8px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    color: #ffffff !important;
    float: right;
    margin: 0px 0px;
}

.text-primary-uvm
{
    color: #d7282f;
}

 .text-second-uvm
 {
   color: #0b0b0b;
 }


 #button-select-umv a:hover
   {
     background-color: #d7282f;
     cursor: default;
 }

 /**
 Enlaces
  Vacantes destacadas
  */
 .enlaces-destacados a:hover
 {
     color: #767676;
     cursor: default;

 }
